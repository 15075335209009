<template>
  <div class="smart">
    <img src="../../assets/product/smart/1.png" class="headerimg" />

    <div class="characteristic">
      <div class="title">智能硬件 AI 语音助手的特性</div>
      <div class="characteristiclist">
        <div class="characteristicinfo">
          <div class="characteristicheard">
            <img src="../../assets/product/smart/2.png" />
            <div class="characteristictext">技术优势</div>
          </div>
          <div class="text">
            提供行业的全链路 AI
            语音技术，包括语音识别、语义理解、语音合成、知识图谱、AI
            识图能力以及机器翻译等能力。
          </div>
        </div>
        <div class="characteristicinfo">
          <div class="characteristicheard">
            <img src="../../assets/product/smart/3.png" />
            <div class="characteristictext">开放定制</div>
          </div>
          <div class="text">
            提供唤醒词、TTS（语音合成播报）、回复语个性化定制，允许自建技能满足个性化需求，提供可协商的数据开放接口。
          </div>
        </div>
        <div class="characteristicinfo">
          <div class="characteristicheard">
            <img src="../../assets/product/smart/4.png" />
            <div class="characteristictext">快速便捷</div>
          </div>
          <div class="text">
            在开放平台自主操作，通过标准化的 UI
            数据模板，一次性接入各种类型技能，帮助客户及方案商降低接入成本，高效快捷的接入智能服务。
          </div>
        </div>
      </div>

      <div class="characteristiclist">
        <div class="characteristicinfo">
          <div class="characteristicheard">
            <img src="../../assets/product/smart/5.png" />
            <div class="characteristictext">多方式接入</div>
          </div>
          <div class="text">
            提供 Android、Linux 等多个平台的
            SDK，客户可通过较小成本实现快速接入。如客户对设备对接 AI
            后台的实现有自己的需求，可选择云端 API 的接入方式。
          </div>
        </div>
        <div class="characteristicinfo">
          <div class="characteristicheard">
            <img src="../../assets/product/smart/6.png" />
            <div class="characteristictext">优质内容服务</div>
          </div>
          <div class="text">
            一站式提供海量优质内容及丰富的语音交互技能，包括音乐、视频、新闻以及有声节目和广播电台等。
          </div>
        </div>
        <div class="characteristicinfo">
          <div class="characteristicheard">
            <img src="../../assets/product/smart/7.png" />
            <div class="characteristictext">软硬一体化</div>
          </div>
          <div class="text">
            输出智能硬件参考设计及硬件方案咨询能力，方便客户充分理解并选择适合的方案。提供小微认证及品牌授权服务，为客户产品保驾护航。
          </div>
        </div>
      </div>
    </div>
    <div class="adhibition">
      <div class="title">应用场景</div>

      <div class="adhibitioninfo">
        <div class="adhibitiontitle">智能音箱</div>
        <div class="adhibitiontext">
          智能硬件 AI
          语音助手提供有屏和无屏两大品类的智能音箱整体解决方案，包含全链路 AI
          能力，例如声学前端、ASR、NLP 和
          TTS；同时还全面整合了音乐、电台和视频等行业的优质内容。
        </div>
        <div class="adhibitionimg">
          <img src="../../assets/product/smart/8.png" />
        </div>
      </div>
    </div>
    <div class="case">
      <div class="title">客户案例</div>
      <div class="caseimg">
        <img src="../../assets/product/smart/9.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="less">
.case {
  margin: 140px 0;
  .caseimg {
    margin-top: 107px;
    text-align: center;
    img {
      width: 1399px;
    }
  }
}

.smart {
  .characteristic {
    margin: 140px;

    .characteristiclist {
      display: flex;
      width: 80%;
      margin: auto;
      margin-top: 100px;
      justify-content: space-between;

      .characteristicinfo {
        width: 400px;

        .characteristicheard {
          text-align: center;
          font-size: 30px;
          font-weight: 500;
          line-height: 45px;

          img {
            width: 100px;
            height: 100px;
          }

          .characteristictext {
            margin-top: 36px;
            font-size: 30px;
            font-weight: 500;
            color: #333333;
            line-height: 45px;
          }
        }

        .text {
          margin-top: 20px;
          font-size: 24px;
          font-weight: 500;
          color: #616b80;
          line-height: 40px;
        }
      }
    }
  }

  .adhibition {
    background: #f5f5f9;
    padding-top: 64px;
    padding-bottom: 88px;

    .adhibitioninfo {
      width: 69%;
      margin: 0 auto;
      .adhibitiontitle {
        font-size: 30px;
        font-weight: 500;
        line-height: 45px;
      }
      .adhibitiontext {
        font-size: 24px;
        font-weight: 400;
        color: #616b80;
        line-height: 48px;
        margin-top: 40px;
      }

      .adhibitionimg {
        margin-top: 80px;
        img {
          width: 100%;
        }
      }
    }
  }

  .title {
    text-align: center;
    font-size: 36px;
    font-weight: 500;
    line-height: 54px;
  }
}
</style>
